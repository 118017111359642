<template>
    <div class="dialog-wrapper">
        <el-dialog title="" :visible.sync="dialogTableVisible" :show-close="false">
            <p style="font-size: 16px; padding-bottom: 15px;">
                以下为您当前可发起提现申请的月账单，勾选您想结算的账单提交申请：
            </p>
            <el-table :data="monthListData"
                      ref="multipleTable" max-height="200"
                      @selection-change="handleSelectionChange"
                      header-cell-class-name="header-cell-settle-selection"
            >
                <el-table-column
                    type="selection"
                    width="55"
                    :selectable="handleSelectable"
                />
                <el-table-column property="month" label="月份" />
                <el-table-column label="月账单金额">
                    <template slot-scope="scope">
                        <p>&yen;{{ scope.row.totalAmount | formatPrice }}</p>
                    </template>
                </el-table-column>
            </el-table>
            <p style="padding-top: 20px">* <span style="color: red;">2020年8月之后（含8月）的账单，不能和8月之前的账单合并申请；</span>2019年11月及之前的结算的账单请联系客服线下申请提现</p>
            <p>* 已过期、未确认、已驳回和已申请过提现的账单不属于可申请提现账单</p>
            <p>* 在账单结算有效期内，建议您多个月账单一起提现，避免重复开票、寄发票给您增添麻烦</p>
            <p style="padding-top: 18px; " v-if="totalAmount > 0">当前勾选月份账单总金额：<span style="font-weight:700">&yen;{{ totalAmount }}</span></p>
            <span slot="footer" class="dialog-footer">
                <el-button @click="handleCancel">取消</el-button>
                <el-button type="primary" @click="handleConfirm">确定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import NP from '../../utils/safe-number.js';
import formatPrice from '../../utils/format-price';

export default {
    data() {
        return{
            dialogTableVisible: true,
            selectList: [],
            selectIds: [],
            totalAmount: 0,
            monthListData: (this.monthList || []).map((item) => {
                let arr = (item.month || '').split('-');
                return {
                    ...item,
                    y: Number(arr[0]),
                    m: Number(arr[1])
                };
            })
        };
    },
    props: ['monthList'],
    filters: {
        formatPrice
    },
    methods: {
        handleSelectionChange(e) {
            this.totalAmount = 0;
            this.selectList = [...e];
            this.selectList.forEach((value) => {
                this.selectIds.push(value.id);
                this.totalAmount = NP.plus(this.totalAmount, value.totalAmount);
            });
        },
        handleSelectable(row) {
            if(!this.selectList.length) {
                return true;
            }
            let selectedRow = this.selectList[0];
            if(this.isTimeRt202008(selectedRow.y, selectedRow.m)) {
                return this.isTimeRt202008(row.y, row.m);
            }
            if(!this.isTimeRt202008(selectedRow.y, selectedRow.m)) {
                return !this.isTimeRt202008(row.y, row.m);
            }
        },
        // 是否202008之后（含08）
        isTimeRt202008(y, m) {
            if(y > 2020) {
                return true;
            }
            if(y === 2020 && m >= 8) {
                return true;
            }
            return false;
        },
        handleHeaderCellStyle(row, column, rowIndex, columnIndex) {
            if(rowIndex === 1 && columnIndex === 1) {
                return {
                    color: 'red'
                };
            }
        },
        handleCancel() {
            this.$emit('closeDialog');
        },
        handleConfirm() {
            if(this.totalAmount === 0){
                this.$message.error('请至少选择一项');
                return;
            }
            this.$emit('confirmBill', {
                withdrawIdList: this.selectIds,
                totalAmount: this.totalAmount
            });
            this.selectList = [];
            this.selectIds = [];
        }
    }
};
</script>

<style lang='scss'>
.header-cell-settle-selection .cell .el-checkbox {
    display: none;
}
</style>
