<template>
    <div class="withdrawal">
        <div class="withdrawal-title">提现申请</div>
        <div class="withdrawal-top">
            <div class="withdrawal-remark">
                <el-alert
                    title="每年3.31之前必须把前一年产生的账单结清，逾期账单无法再发起提现申请（如，2019年的账单需在2020-3-31之前结清）"
                    type="warning"
                    show-icon
                />
            </div>
            <div class="withdrawal-button">
                <el-button type="primary" plain @click="getWithdrawMonthList">提现申请</el-button>
            </div>
        </div>
        <div class="withdrawal-table">
            <div class="table-header">
                <span class="title">申请记录</span>
                <withdrawal-tooltip>
                    若您有驳回的月账单需线下申请提现，申请记录不在表中。
                </withdrawal-tooltip>
            </div>

            <div class="withdrawal-list">
                <el-table :data="list" style="width: 100%;">
                    <el-table-column label="申请发起时间" width="150">
                        <template slot-scope="scope">
                            <p>{{ scope.row.applicationTime | formatTime('yyyy-MM-dd') }}</p>
                        </template>
                    </el-table-column>
                    <el-table-column property="settleMonth" label="结算月份" width="150" />
                    <el-table-column label="结算总金额" width="150">
                        <template slot-scope="scope">
                            <p>&yen;{{ scope.row.totalAmount | formatPrice }}</p>
                        </template>
                    </el-table-column>
                    <el-table-column label="发票信息" width="150">
                        <template slot-scope="scope">
                            <el-button type="text"
                                       style="display: block"
                                       v-if="scope.row.invoiceInfo === 0"
                                       @click="inputInvoiceInfo(scope.row.applicationId)"
                            >
                                点此录入
                            </el-button>
                            <el-button type="text"
                                       style="display: block"
                                       v-if="scope.row.invoiceInfo === 1"
                                       @click="getInvoiceInfo(scope.row.applicationId)"
                            >
                                查看
                            </el-button>
                        </template>
                    </el-table-column>
                    <el-table-column label="发票接收状态">
                        <template slot-scope="scope">
                            <div v-if="scope.row.acceptRecordList && scope.row.acceptRecordList.length>0">
                                <div v-for="item in scope.row.acceptRecordList" :key="item.recordId">
                                    <div v-if="item.status === 1">
                                        {{ item.operateTime | formatTime('yyyy-MM-dd') }}&nbsp;&nbsp;&nbsp;平台接收发票
                                    </div>
                                    <div v-if="item.status === 2">
                                        {{ item.operateTime | formatTime('yyyy-MM-dd') }}&nbsp;&nbsp;&nbsp;平台驳回发票
                                        <span class="detail" @click="rejectDetail(item)">查看详情</span>
                                    </div>
                                </div>
                            </div>
                            <div v-else>暂无</div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="settle-page" v-if="maxCount">
                <pagination @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page="pageIndex"
                            :page-size="pageSize"
                            :total="maxCount"
                />
            </div>
        </div>
        <tableDialog
            @closeDialog="closeDialog"
            @confirmBill="confirmBill"
            :month-list="monthList"
            v-if="monthList && monthList.length > 0 && dialogVisible"
        />
        <invoiceDialog
            :withdraw-id-list="withdrawIdList"
            :total-amount="totalAmount"
            @closeinvoiceDialog="closeinvoiceDialog"
            @confirmInvoice="confirmInvoice"
            v-if="invoiceDialog"
        />
        <inputInvoiceDialog
            @closeInputDialog="closeInputDialog"
            @saveInvoice="saveInvoice"
            v-if="inputFlag"
            :first-flag="firstFlag"
            :application-id="applicationId"
        />
        <invoiceDetailDialog
            @hideDetailDialog="hideDetailDialog"
            @addInvoice="addInvoice"
            v-if="invoiceList && invoiceList.length>0 &&invoiceDeail"
            :list="invoiceList"
        />
        <rejectInvoiceDialog v-if="rejectInvoice" @hideReject="hideReject" :reject-reason="rejectReason" />
        <el-dialog
            title="您当前无可发起提现申请的账单"
            :visible.sync="noBillDialog"
            width="45%"
            :show-close="false"
        >
            <div>
                <p>
                    * <span style="color: red;">2020年8月之后（含8月）的账单，不能和8月之前的账单合并申请；</span>
                    2019年11月及之前的结算的账单请联系客服线下申请提现
                </p>
                <p>* 已过期、未确认、已驳回和已申请过提现的账单不属于可申请提现账单</p>
                <p>* 在账单结算有效期内，建议您多个月账单一起提现，避免重复开票、寄发票给您增添麻烦</p>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="noBillDialog = false">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import Tooltip from '../../components/settle/tooltip';
import Pagination from '../../components/common/pagination';
import tableDialog from '../../components/settle/table-dialog';
import invoiceDialog from '../../components/settle/invoice-dialog';
import inputInvoiceDialog from '../../components/settle/inputInvoice-dialog';
import invoiceDetailDialog from '../../components/settle/invoiceDetail-dialog';
import rejectInvoiceDialog from '../../components/settle/reject-invoice';

export default {
    data() {
        return{
            list: [],
            monthList: [],
            pageIndex: 1,
            pageSize: 20,
            maxCount: 0,
            dialogVisible: false,
            invoiceDialog: false,
            totalAmount: 0,
            withdrawIdList: [],
            noBillDialog: false,
            inputFlag: false,
            firstFlag: false,
            applicationId: '',
            invoiceList: [],
            invoiceDeail: false,
            rejectReason: '',
            rejectInvoice: false
        };
    },
    components: {
        withdrawalTooltip: Tooltip,
        Pagination,
        tableDialog,
        invoiceDialog,
        inputInvoiceDialog,
        invoiceDetailDialog,
        rejectInvoiceDialog
    },

    async created() {
        await this.getWithdrawRecordList();
    },
    methods: {
        async getWithdrawRecordList() {
            const data = await this.$request({
                url: '/api/settle/getWithdrawRecordList',
                params: {
                    pageIndex: this.pageIndex,
                    pageSize: this.pageSize
                }
            });
            this.list=data.zhuankeWithdrawRecordList;
            this.maxCount = data.maxCount;
        },
        async getWithdrawMonthList() {
            const data = await this.$request({
                url: '/api/settle/getWithdrawMonthList'
            });
            this.monthList = data;
            if(this.monthList && this.monthList.length > 0) {
                this.dialogVisible = true;
            }else {
                this.noBillDialog = true;
            }
        },
        handleSizeChange(size) {
            this.pageSize = size;
            this.pageIndex = 1;
            this.getWithdrawRecordList();
        },
        handleCurrentChange(p) {
            this.pageIndex = p;
            this.getWithdrawRecordList();
        },
        closeDialog() {
            this.dialogVisible = false;
        },
        closeinvoiceDialog() {
            this.invoiceDialog = false;
        },
        confirmBill(e) {
            this.withdrawIdList = e.withdrawIdList;
            this.totalAmount = e.totalAmount;
            this.dialogVisible = false;
            this.invoiceDialog = true;
        },
        confirmInvoice() {
            this.getWithdrawRecordList();
            this.invoiceDialog = false;
        },
        inputInvoiceInfo(e) {
            this.applicationId=e;
            this.firstFlag = true;
            this.inputFlag = true;

        },
        closeInputDialog() {
            this.inputFlag =false;
        },
        hideDetailDialog() {
            this.getWithdrawRecordList();
            this.invoiceDeail = false;
        },
        saveInvoice() {
            this.getInvoiceList();
            this.inputFlag = false;
            this.invoiceDeail = true;
        },
        addInvoice() {
            this.invoiceDeail = false;
            this.firstFlag = false;
            this.inputFlag = true;
        },
        getInvoiceInfo(e) {
            this.applicationId=e;
            this.getInvoiceList();
            this.firstFlag = false;
            this.invoiceDeail = true;
        },
        rejectDetail(e) {
            this.rejectReason = e.rejectReason;
            this.rejectInvoice = true;
        },
        hideReject() {
            this.rejectInvoice = false;
        },
        async getInvoiceList() {
            const data = await this.$request({
                url: '/api/settle/getInvoiceList',
                params: {
                    applicationId: Number(this.applicationId)
                }
            });
            this.invoiceList = data;
        }
    }

};
</script>

<style lang='scss' scoped>
.withdrawal{
    .withdrawal-title {
        font-size: 16px;
        font-weight: 700;
        margin-bottom: 15px;
    }
    .withdrawal-top {
        background: #fff;
        padding: 16px ;
        border-radius: 4px;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
        .withdrawal-remark{
            margin-bottom: 15px;
        }
    }
    .withdrawal-table {
        margin-top: 15px;
        background: #fff;
        padding: 16px ;
        border-radius: 4px;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
        margin-bottom:25px;
        .table-header {
            .title {
                font-size:16px;
                font-weight: 700;
                margin-left:5px;
            }
        }
        .withdrawal-list{
            .detail {
                margin-left: 10px;
                cursor: pointer;
                color: #FF1E32;
            }
        }
        .settle-page {
            margin-top: 20px;
        }
    }
}
</style>
<style lang="scss">
.withdrawal-list {
     .el-table .cell{
        white-space: pre-wrap
    }
}
</style>
