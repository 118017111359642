import { render, staticRenderFns } from "./reject-invoice.vue?vue&type=template&id=32266c32&scoped=true&"
import script from "./reject-invoice.vue?vue&type=script&lang=js&"
export * from "./reject-invoice.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "32266c32",
  null
  
)

export default component.exports