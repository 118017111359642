<template>
    <div>
        <el-dialog
            title="发票驳回提示"
            :visible.sync="rejectInvoice"
            :show-close="false"
            width="40%"
        >
            <div style="margin-bottom: 20px;">驳回原因：{{ rejectReason }}</div>
            <div>请重新开票，并在发票信息录入弹窗新增发票信息，把新发票信息录入</div>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="handleConfirm">确定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
export default {
    data() {
        return {
            rejectInvoice: true
        };
    },
    props: ['rejectReason'],
    methods: {
        handleConfirm() {
            this.$emit('hideReject');
        }
    }
};
</script>

<style lang='scss' scoped>

</style>