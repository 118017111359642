<template>
    <div>
        <el-dialog
            title="你已保存的发票信息"
            :visible.sync="detailInvoice"
            :show-close="false"
            width="40%"
        >
            <div class="list-wrap">
                <div class="list" v-for="item in list" :key="item.invoiceCode">
                    <p v-if="item.addReason === 1 ">新增原因：一次申请开多张发票</p>
                    <p v-if="item.addReason === 2 ">新增原因：发票被驳回重新开票</p>
                    <p v-if="item.otherReason">新增原因：{{ item.otherReason }}</p>
                    <p>发票代码：{{ item.invoiceCode }}</p>
                    <p>发票号码：{{ item.invoiceNum }}</p>
                    <p>发票金额：&yen;{{ item.invoiceAmount }}</p>
                    <p style="font-weight:700">{{ item.acceptRecord }}</p>
                </div>
            </div>
            <div class="remark" @click="addInvoice">新增发票信息</div>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="handleConfirm">确定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
export default {
    data() {
        return {
            detailInvoice: true
        };
    },
    props: {
        list: {
            type: Array
        }
    },
    // mounted() {
    //     this.list.forEach((value) => {
    //         Object.assign(value, {reasonDesc: ''});
    //         if(value.addReason === 1) {

    //             value.reasonDesc = '一次申请开多张发票';
    //         } else if(value.addReason === 2) {
    //             value.reasonDesc = '发票被驳回重新开票';
    //         } else {
    //             value.reasonDesc = value.otherReason;
    //         }
    //     });
    // },
    methods: {
        addInvoice() {
            this.$emit('addInvoice');
        },
        handleConfirm() {
            this.$emit('hideDetailDialog');
        }
    }
};
</script>

<style scoped lang='scss'>
.list-wrap {
    overflow: auto;
    max-height: 280px;
}
.list{
    background: #fff;
    padding: 15px ;
    border-radius: 4px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}

.remark{
    margin-top: 15px;
    cursor: pointer;
    color: #ff1e32;
}
</style>