<template>
    <div class="invoice-wrapper">
        <el-dialog title="" :visible.sync="dialoginvoticeVisible" :show-close="false">
            <div class="top">
                您当前申请提现账单总金额 <span style="color: #ff0000">&yen;{{ totalAmount }}</span> ，
                请根据下方开票信息<span style="color: #ff0000">开具等额发票</span>，
                开票后请及时到【结算中心-提现申请-申请记录】<span style="color: #ff0000">录入发票信息</span>，我们收到发票后，
                会尽快审核处理您的提现申请。
            </div>
            <div class="invoice-info">
                <div class="title">开票信息</div>
                <p>小规模纳税人可开具增值税专用发票，税率为3%；一般纳税人开增值税专用发票，且税率为6%；发票上的公章应盖“发票专用章”。</p>
                <div class="detail">
                    <br>
                    <p style="font-weight: bold;">※  2020年8月<span style="font-size: 16px;color: red;">之后</span>账单（含8月），开票信息如下</p>
                    <br>
                    <p>公司名称：杭州无尾熊电子商务有限公司</p>
                    <p>税号：91330108MA27YLFQ8X</p>
                    <p>地址：浙江省杭州市滨江区长河街道网商路599号4幢416室</p>
                    <p>电话：0571-85022088</p>
                    <p>开户行：工商银行股份有限公司杭州分行</p>
                    <p>账号：1202021109800045612</p>
                    <p>开票内容：技术服务费、信息技术服务费、推广服务费</p>
                </div>
                <div class="detail">
                    <br>
                    <p style="font-weight: bold;">※  2020年8月<span style="font-size: 16px;color: red;">之前</span>账单，开票信息如下</p>
                    <br>
                    <p>公司名：考拉海购（杭州）科技有限公司</p>
                    <p>税号：913301003296004073</p>
                    <p>地址：浙江省杭州市滨江区长河街道网商路699号4号楼5楼504室</p>
                    <p>电话：0571-89852052</p>
                    <p>开户行：工商银行股份有限公司杭州分行</p>
                    <p>账号：1202021109900198112</p>
                    <p>开票内容：技术服务费、信息技术服务费、推广服务费</p>
                </div>
            </div>
            <div class="invoice-adress">
                <div class="title">发票邮寄地址：</div>
                <div class="detail">
                    <p>邮寄地址：杭州市余杭区五常街道文一西路969号6号楼小邮局，考拉联盟运营组（收）</p>
                    <p>联系电话：0571-83817317</p>
                    <p>邮政编码：311121</p>
                </div>
            </div>

            <span slot="footer" class="dialog-footer">
                <el-button @click="handleCancel">取消申请</el-button>
                <el-button type="primary" @click="handleConfirm">提交申请</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
export default {
    data() {
        return {
            dialoginvoticeVisible: true
        };
    },
    props: ['withdrawIdList', 'totalAmount'],
    methods: {
        handleCancel() {
            this.$emit('closeinvoiceDialog');
        },
        async handleConfirm() {
            const data = await this.$request({
                url: '/api/settle/commitWithdraw',
                method: 'post',
                data: {
                    withdrawIdList: this.withdrawIdList
                },
                origin: true
            });
            if(data.code === 200) {
                this.$message({
                    message: '提交成功',
                    type: 'success'
                });
            }
            this.$emit('confirmInvoice');
        }
    }
};
</script>

<style lang='scss' scoped>
.invoice-wrapper {
    .invoice-info, .invoice-adress {
        .title {
            font-size: 16px;
            font-weight: 700;
            margin-top: 20px;
            margin-bottom: 5px;
        }
        .detail {
            padding-left: 10px;
        }
    }
}
</style>
