<template>
    <div>
        <el-dialog
            title="请正确录入发票信息"
            :visible.sync="inputInvoice"
            :show-close="false"
            width="40%"
        >
            <div>
                <el-form :model="numberValidateForm" ref="numberValidateForm" label-width="100px" class="demo-ruleForm">
                    <el-form-item label="新增原因" prop="addReason" v-if="!firstFlag">
                        <el-select v-model="numberValidateForm.addReason" placeholder="请选择">    
                            <el-option
                                v-for="item in options"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                            />
                        </el-select>  
                        <span>
                            <el-input v-model="otherReason" v-if="numberValidateForm.addReason === 3" style="width:100px; margin-left: 20px;" />
                        </span>
                    </el-form-item>
                    <el-form-item label="发票代码" prop="invoiceCode" 
                                  :rules="[
                                      { required: true, message: '发票代码不能为空'}]"
                    >
                        <el-input v-model="numberValidateForm.invoiceCode" />
                    </el-form-item>
                    <el-form-item label="发票号码" prop="invoiceNum"
                                  :rules="[
                                      { required: true, message: '发票号码不能为空'}]"
                    >
                        <el-input v-model="numberValidateForm.invoiceNum" />
                    </el-form-item>
                    <el-form-item label="发票金额" prop="invoiceAmount"
                                  :rules="[
                                      { required: true, message: '发票金额不能为空'},
                                  ]"
                    >
                        <el-input v-model="numberValidateForm.invoiceAmount" style="width: 380px" /> &nbsp;元
                    </el-form-item>
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="handleClose">取 消</el-button>
                <el-button type="primary" @click="handleConfirm">保存</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import isNumber from '../../utils/isNumber.js';
import isInteger from '../../utils/isInteger.js';

export default {
    data() {
        return {
            inputInvoice: true,
            otherReason: '',
            numberValidateForm: {
                addReason: 1,
                invoiceCode: '',
                invoiceNum: '',
                invoiceAmount: ''
            },
            options: [{
                value: 1,
                label: '一次申请开多张发票'
            }, {
                value: 2,
                label: '发票被驳回重新开票'
            }, {
                value: 3,
                label: '其他'
            }]
        };
    },
    props: ['firstFlag', 'applicationId'],
    methods: {
       
        handleClose() {
            this.$emit('closeInputDialog');
        },
        /*eslint-disable*/
        async handleConfirm() {
            const form = this.numberValidateForm;
            if(form.invoiceCode ==='' || form.invoiceNum ==='' ||form.invoiceAmount ===''){
                this.$message({
                    message: '所有输入框必须填写',
                    type: 'error'
                });
                return;
            }
            if(!isInteger(form.invoiceCode)) {
                this.$message({
                    message: '发票代码必须为数字',
                    type: 'error'
                });
                return;
            }
            if(!isInteger(form.invoiceNum)) {
                this.$message({
                    message: '发票号码必须为数字',
                    type: 'error'
                });
                return;
            }
            if(!isNumber(form.invoiceAmount)) {
                this.$message({
                    message: '发票金额必须为数字',
                    type: 'error'
                });
                return;
            }
             if(this.numberValidateForm.addReason === 3 && this.otherReason === '') {
                this.$message({
                    message: '发票原因必填',
                    type: 'error'
                });
                return;
            }
            const data = await this.$request({
                url: '/api/settle/commitInvoiceInfo',
                params: {
                    applicationId: this.applicationId,
                    invoiceCode: form.invoiceCode,
                    invoiceNum: form.invoiceNum,
                    invoiceAmount: form.invoiceAmount,
                    addReason: this.firstFlag ? '' : form.addReason,
                    otherReason:this.otherReason
                },
                origin: true
            });
            if(data.code ===200) {
                this.$emit('saveInvoice');
            }
        }
        /*eslint-disable*/
    }
};
</script>

<style lang='scss' scoped>

</style>